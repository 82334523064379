// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json__Decode from "../../../../node_modules/rescript-json/lib/es6_global/src/Json__Decode.bs.js";
function decode(j, decoder) {
  var errorToString = function (_error) {
    while (true) {
      var error = _error;
      switch (error.TAG | 0) {
        case /* Failure */0:
          return error._0;
        case /* Index */1:
        case /* Field */2:
          _error = error._1;
          continue;
        case /* OneOf */3:
          _error = error._0;
          continue;
      }
    }
    ;
  };
  var result = Json__Decode.decodeValue(j, decoder);
  if (result.TAG === /* Ok */0) {
    return {
      TAG: /* Ok */0,
      _0: result._0
    };
  } else {
    return {
      TAG: /* Error */1,
      _0: errorToString(result._0)
    };
  }
}
export { decode };
/* Json__Decode Not a pure module */