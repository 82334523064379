


function Impl(T) {
  return {};
}

export {
  Impl ,
}
/* No side effect */
