// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as $$Promise from "../../../../node_modules/@ryyppy/rescript-promise/lib/es6_global/src/Promise.bs.js";
var unwrapJsError = function (exn) {
  if (typeof exn != "object") {
    return exn;
  }
  if (!exn.hasOwnProperty("RE_EXN_ID") || !exn.hasOwnProperty("_1")) {
    return exn;
  }
  if (typeof exn["RE_EXN_ID"] == "string" && exn["RE_EXN_ID"].includes("JsError") && typeof exn["_1"] == "object" && exn["_1"] instanceof Error) {
    return exn["_1"];
  } else {
    return exn;
  }
};
function $$catch(promise, fn) {
  return $$Promise.$$catch(promise, function (e) {
    return Curry._1(fn, unwrapJsError(e));
  });
}
export { unwrapJsError, $$catch };
/* No side effect */