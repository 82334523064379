// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";

function toDeccoError(e) {
  var errorStr = Json__Decode.errorToString(e);
  return {
          path: "unknown from rescript-json",
          message: errorStr,
          value: null
        };
}

function Make(Serializable) {
  var t_encode = Serializable.toJson;
  var t_decode = function (json) {
    var ok = Json__Decode.decodeValue(json, Serializable.fromJson);
    if (ok.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: ok._0
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: toDeccoError(ok._0)
            };
    }
  };
  var codec = [
    t_encode,
    t_decode
  ];
  return {
          t_encode: t_encode,
          t_decode: t_decode,
          codec: codec
        };
}

function MakeRecursive(RecursiveSerializable) {
  var t_encode = RecursiveSerializable.toJson;
  var t_decode = function (json) {
    var ok = Json__Decode.decodeValue(json, Curry._1(RecursiveSerializable.fromJson, undefined));
    if (ok.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: ok._0
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: toDeccoError(ok._0)
            };
    }
  };
  var codec = [
    t_encode,
    t_decode
  ];
  return {
          t_encode: t_encode,
          t_decode: t_decode,
          codec: codec
        };
}

export {
  Make ,
  MakeRecursive ,
}
/* Json__Decode Not a pure module */
