// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
function TMake($star) {
  var toString = function (key) {
    return key;
  };
  var fromString = function (key) {
    return key;
  };
  return {
    toString: toString,
    fromString: fromString
  };
}
function toString(key) {
  return key;
}
function fromString(key) {
  return key;
}
var T = {
  toString: toString,
  fromString: fromString
};
var delimiter = "__";
var empty = "nonamespace";
function toNamespacedKey(nonNamespacedKey, namespace) {
  var namespace$1 = Belt_Option.map(namespace, toString);
  if (namespace$1 !== undefined && namespace$1 !== "") {
    return nonNamespacedKey + delimiter + namespace$1;
  } else {
    return nonNamespacedKey;
  }
}
function fromNamespacedKey(namespacedKey) {
  var split = namespacedKey.split(delimiter);
  var nonNamespacedKey = Belt_Option.getWithDefault(Belt_Array.get(split, 0), namespacedKey);
  var namespace = Belt_Option.map(Belt_Array.get(split, 1), fromString);
  return [nonNamespacedKey, namespace];
}
function getDictByNamespace(dict, namespace) {
  return Js_dict.fromArray(Belt_Array.keepMap(Belt_Array.map(Js_dict.entries(dict), function (param) {
    return [fromNamespacedKey(param[0]), param[1]];
  }), function (param) {
    var match = param[0];
    if (Caml_obj.equal(match[1], namespace)) {
      return [match[0], param[1]];
    }
  }));
}
function map(namespace, fn) {
  return Curry._1(fn, Belt_Option.getWithDefault(namespace, empty));
}
function forEach(namespace, fn) {
  Curry._1(fn, Belt_Option.getWithDefault(namespace, empty));
}
export { TMake, T, fromString, toString, delimiter, empty, toNamespacedKey, fromNamespacedKey, getDictByNamespace, map, forEach };
/* No side effect */