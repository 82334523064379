// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as URLUtils$Showside from "./URLUtils.bs.js";
var rollbarAccessToken = process.env.ROLLBAR_ACCESS_TOKEN;
var nodeEnv = process.env.NODE_ENV;
var vercelGitCommitSha = process.env.VERCEL_GIT_COMMIT_SHA;
var apiHostnameProduction = "https://api.maker.co";
var apiHostnameStaging = "https://api-git-master-makerco.vercel.app";
var apiHostnameLocal = "http://localhost:3001";
function apiEnvToString(env) {
  if (typeof env !== "number") {
    return env._0;
  }
  switch (env) {
    case /* Staging */0:
      return "staging";
    case /* Production */1:
      return "production";
    case /* Local */2:
      return "local";
  }
}
function getApiEnv(devMode) {
  var partial_arg = /^(smartnav|api)\-([^\-]+)\-makerco\.vercel\.app$/;
  var isHostnameSafe = function (param) {
    return partial_arg.test(param);
  };
  var devMode$1 = devMode.toLowerCase();
  switch (devMode$1) {
    case "dev":
    case "local":
      return /* Local */2;
    case "head":
    case "master":
    case "preview":
    case "staging":
      return /* Staging */0;
    default:
      if (Curry._1(isHostnameSafe, devMode$1)) {
        return /* Hostname */{
          _0: devMode$1
        };
      } else {
        return /* Production */1;
      }
  }
}
function getApiHostname(devMode) {
  if (typeof devMode !== "number") {
    return "https://" + devMode._0;
  }
  switch (devMode) {
    case /* Staging */0:
      return apiHostnameStaging;
    case /* Production */1:
      return apiHostnameProduction;
    case /* Local */2:
      return apiHostnameLocal;
  }
}
function isTest(param) {
  if (nodeEnv !== "production") {
    return true;
  } else {
    return URLUtils$Showside.getCurrentHostname(undefined).endsWith(".vercel.app");
  }
}
export { rollbarAccessToken, nodeEnv, vercelGitCommitSha, apiHostnameProduction, apiHostnameStaging, apiHostnameLocal, apiEnvToString, getApiEnv, getApiHostname, isTest };
/* rollbarAccessToken Not a pure module */