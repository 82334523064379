// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SWR from "../../../../node_modules/rescript-swr/lib/es6_global/src/SWR.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../../node_modules/bs-fetch/lib/es6_global/src/Fetch.bs.js";
import * as Js_exn from "../../../../node_modules/rescript/lib/es6/js_exn.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Rollbar from "../../../../node_modules/rescript-rollbar/lib/es6_global/src/Rollbar.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import IsNetworkError from "is-network-error";
import * as URLUtils$Showside from "./URLUtils.bs.js";
import * as PromiseUtils$Showside from "./PromiseUtils.bs.js";
import * as RollbarInstance$Showside from "./RollbarInstance.bs.js";
var IsNetworkError$1 = {};
var undefinedErrorMessage = "Unknown error occurred";
function makeError(res, message, fullErrorMessage, param) {
  var statusCode = res.status;
  var url = res.url;
  var pathname = Belt_Option.getWithDefault(URLUtils$Showside.getPathname(URLUtils$Showside.resolveRelativePath(url)), url);
  return {
    exn: {
      RE_EXN_ID: SWR.ServerError,
      _1: "Error " + statusCode.toString() + " in API " + pathname + ": " + message + ""
    },
    fullErrorMessage: fullErrorMessage,
    response: res
  };
}
function truncateTo100Characters(str) {
  if (str.length > 100) {
    return str.slice(0, 100) + "...";
  } else {
    return str;
  }
}
function extractUsefulErrorMessageFromResponse(res) {
  return Fetch.$$Response.text(res).then(function (text) {
    var json;
    try {
      json = Caml_option.some(JSON.parse(text));
    } catch (e) {
      json = undefined;
    }
    var message = truncateTo100Characters(text);
    var message$1 = message.trim() === "" ? undefinedErrorMessage : message;
    var obj = Belt_Option.flatMap(json, Js_json.decodeObject);
    if (obj === undefined) {
      return Promise.resolve(makeError(res, message$1, text, undefined));
    }
    var displayError = Belt_Option.flatMap(Js_dict.get(Caml_option.valFromOption(obj), "error"), Js_json.decodeString);
    if (displayError !== undefined) {
      return Promise.resolve(makeError(res, displayError, text, undefined));
    } else {
      return Promise.resolve(makeError(res, message$1, text, undefined));
    }
  });
}
function handleServerResponse(res) {
  var statusCode = res.status;
  if (statusCode >= 200 && statusCode < 300) {
    return Promise.resolve({
      TAG: /* Ok */0,
      _0: res
    });
  } else {
    return extractUsefulErrorMessageFromResponse(res).then(function (error) {
      return {
        TAG: /* Error */1,
        _0: error
      };
    });
  }
}
function reportAllServerErrors(res) {
  return res.then(handleServerResponse).then(function (result) {
    if (result.TAG === /* Ok */0) {
      return Promise.resolve(result._0);
    }
    var error = result._0;
    return RollbarInstance$Showside.error2(error.exn, {
      requestUrl: error.response.url,
      response: error.response,
      fullErrorMessage: error.fullErrorMessage
    }).then(function (param) {
      return Promise.reject(error.exn);
    });
  });
}
function ignoreNotFoundError(res) {
  return res.then(handleServerResponse).then(function (result) {
    if (result.TAG === /* Ok */0) {
      return Promise.resolve(result._0);
    }
    var error = result._0;
    if (error.response.status === 404) {
      return Promise.reject({
        RE_EXN_ID: SWR.ServerError,
        _1: "Not found"
      });
    } else {
      return RollbarInstance$Showside.error2(error.exn, {
        requestUrl: error.response.url,
        response: error.response,
        fullErrorMessage: error.fullErrorMessage
      }).then(function (param) {
        return Promise.reject(error.exn);
      });
    }
  });
}
var Server = {
  makeError: makeError,
  truncateTo100Characters: truncateTo100Characters,
  extractUsefulErrorMessageFromResponse: extractUsefulErrorMessageFromResponse,
  handleServerResponse: handleServerResponse,
  reportAllServerErrors: reportAllServerErrors,
  ignoreNotFoundError: ignoreNotFoundError
};
function ignoreDecode(json) {
  return Promise.resolve({
    TAG: /* Ok */0,
    _0: json
  });
}
function raiseExnIfFound(json, decoder) {
  var decodedObject = Js_json.decodeObject(json);
  var error = Belt_Option.flatMap(Belt_Option.flatMap(decodedObject, function (dict) {
    return Js_dict.get(dict, "error");
  }), Js_json.decodeString);
  var numberOfFields = Belt_Option.getWithDefault(Belt_Option.map(decodedObject, function (object) {
    return Object.keys(object).length;
  }), 0);
  if (error !== undefined && error.trim() !== "" && numberOfFields === 1) {
    return Promise.reject(Js_exn.raiseError(error));
  } else {
    return Curry._1(decoder, json);
  }
}
var SuccessfulResponseErrorBody = {
  raiseExnIfFound: raiseExnIfFound
};
function reportAllDecodingErrors(res, decoder) {
  return res.then(function (res) {
    return Fetch.$$Response.json(res).then(function (json) {
      var decoded = raiseExnIfFound(json, decoder);
      var url = res.url;
      var pathname = Belt_Option.getWithDefault(URLUtils$Showside.getPathname(URLUtils$Showside.resolveRelativePath(url)), url);
      return PromiseUtils$Showside.$$catch(decoded, function (e) {
        var rootCause = Rollbar.$$Error.fromRescript(e);
        var name = Belt_Option.getWithDefault(rootCause.name, "Error");
        var message = Belt_Option.getWithDefault(rootCause.message, undefinedErrorMessage);
        var errorMessage = "Error raised when decoding " + pathname + ": " + name + " " + message + "";
        var error = {
          RE_EXN_ID: SWR.DecodeError,
          _1: errorMessage
        };
        if (message.includes("Failed to fetch dynamically imported module:") || message.includes("Cannot find module") || message.includes("Could not resolve bundle with id")) {
          return Promise.reject(error);
        } else {
          return RollbarInstance$Showside.error2(error, {
            requestUrl: url,
            rawError: e
          }).then(function (param) {
            return Promise.reject(error);
          });
        }
      }).then(function (decoded) {
        if (decoded.TAG === /* Ok */0) {
          return Promise.resolve(decoded._0);
        }
        var e = decoded._0;
        var errorMessage = "Failed to decode response of API " + pathname + ": " + e + "";
        var error = {
          RE_EXN_ID: SWR.DecodeError,
          _1: errorMessage
        };
        return RollbarInstance$Showside.error2(error, {
          requestUrl: url,
          rawError: e
        }).then(function (param) {
          return Promise.reject(error);
        });
      });
    });
  });
}
var Decoding = {
  ignoreDecode: ignoreDecode,
  SuccessfulResponseErrorBody: SuccessfulResponseErrorBody,
  reportAllDecodingErrors: reportAllDecodingErrors
};
function reportExn(promise, url) {
  return PromiseUtils$Showside.$$catch(promise, function (e) {
    var pathname = Belt_Option.getWithDefault(URLUtils$Showside.getPathname(URLUtils$Showside.resolveRelativePath(url)), url);
    var err = Rollbar.$$Error.fromRescript(e);
    var name = Belt_Option.getWithDefault(err.name, "Error");
    var message = Belt_Option.getWithDefault(err.message, undefinedErrorMessage);
    var isNetworkError = IsNetworkError(e);
    if (isNetworkError) {
      return Promise.reject({
        RE_EXN_ID: SWR.NetworkError,
        _1: Belt_Option.getWithDefault(err.message, "Network error")
      });
    } else {
      return RollbarInstance$Showside.jsError2(Rollbar.$$Error.fromRescriptWithNewMessage(e, "Error raised when fetching " + pathname + ": " + name + " " + message + ""), {
        requestUrl: url,
        rawError: err
      }).then(function (param) {
        return Promise.reject(e);
      });
    }
  });
}
function $$fetch$1(method_Opt, body, headers, reportDecodingError, reportServerError, decoder, url) {
  var method_ = method_Opt !== undefined ? method_Opt : /* Get */0;
  var reportServerError$1 = Belt_Option.getWithDefault(reportServerError, reportAllServerErrors);
  var reportDecodingError$1 = Belt_Option.getWithDefault(reportDecodingError, reportAllDecodingErrors);
  return Curry._2(reportDecodingError$1, Curry._1(reportServerError$1, reportExn(fetch(url, Fetch.RequestInit.make(method_, headers, body, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)), url)), decoder);
}
export { IsNetworkError$1 as IsNetworkError, undefinedErrorMessage, Server, Decoding, reportExn, $$fetch$1 as $$fetch };
/* SWR Not a pure module */