// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Js_dict from "../../../../rescript/lib/es6/js_dict.js";
import * as Belt_List from "../../../../rescript/lib/es6/belt_List.js";

function encode(v, indentLevel) {
  return JSON.stringify(v, null, indentLevel);
}

function string(prim) {
  return prim;
}

function $$int(i) {
  return i;
}

function $$float(prim) {
  return prim;
}

function bool(prim) {
  return prim;
}

var $$null = null;

function array(arr, encodeI) {
  return arr.map(Curry.__1(encodeI));
}

function list(l, encodeI) {
  return Belt_List.toArray(l).map(Curry.__1(encodeI));
}

var object = Js_dict.fromArray;

function dict(obj) {
  return obj;
}

export {
  encode ,
  string ,
  $$int ,
  $$float ,
  bool ,
  $$null ,
  array ,
  list ,
  object ,
  dict ,
}
/* null Not a pure module */
