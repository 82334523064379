// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
function getWidth(param) {
  var clientWidth = document.documentElement.clientWidth;
  var innerWidth = window.innerWidth;
  var tmp;
  var exit = 0;
  if (innerWidth !== 0 || clientWidth <= 0) {
    exit = 1;
  } else {
    tmp = clientWidth;
  }
  if (exit === 1) {
    tmp = clientWidth !== 0 ? clientWidth < innerWidth ? clientWidth : innerWidth : innerWidth > 0 || clientWidth >= innerWidth ? innerWidth : clientWidth;
  }
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(window.parentScrollPosition, function (position) {
    return position.window;
  }), function ($$window) {
    return $$window.windowWidth;
  }), tmp);
}
function getHeight(param) {
  var clientHeight = document.documentElement.clientHeight;
  var innerHeight = window.innerHeight;
  var tmp;
  var exit = 0;
  if (innerHeight !== 0 || clientHeight <= 0) {
    exit = 1;
  } else {
    tmp = clientHeight;
  }
  if (exit === 1) {
    tmp = clientHeight !== 0 ? clientHeight > innerHeight ? clientHeight : innerHeight : innerHeight > 0 || clientHeight <= innerHeight ? innerHeight : clientHeight;
  }
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(window.parentScrollPosition, function (position) {
    return position.window;
  }), function ($$window) {
    return $$window.windowHeight;
  }), tmp);
}
export { getWidth, getHeight };
/* No side effect */