var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { dequal } from 'dequal/lite';
import Cache from './cache';
import webPreset from './libs/web-preset';
// cache
var cache = new Cache();
// error retry
function onErrorRetry(_, __, config, revalidate, opts) {
    if (!config.isDocumentVisible()) {
        // if it's hidden, stop
        // it will auto revalidate when focus
        return;
    }
    if (typeof config.errorRetryCount === 'number' &&
        opts.retryCount > config.errorRetryCount) {
        return;
    }
    // exponential backoff
    var count = Math.min(opts.retryCount, 8);
    var timeout = ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval;
    setTimeout(revalidate, timeout, opts);
}
// client side: need to adjust the config
// based on the browser status
// slow connection (<= 70Kbps)
var slowConnection = typeof window !== 'undefined' &&
    // @ts-ignore
    navigator['connection'] &&
    // @ts-ignore
    ['slow-2g', '2g'].indexOf(navigator['connection'].effectiveType) !== -1;
// config
var defaultConfig = __assign({ 
    // events
    onLoadingSlow: function () { }, onSuccess: function () { }, onError: function () { }, onErrorRetry: onErrorRetry, errorRetryInterval: (slowConnection ? 10 : 5) * 1000, focusThrottleInterval: 5 * 1000, dedupingInterval: 2 * 1000, loadingTimeout: (slowConnection ? 5 : 3) * 1000, refreshInterval: 0, revalidateOnFocus: true, revalidateOnReconnect: true, refreshWhenHidden: false, refreshWhenOffline: false, shouldRetryOnError: true, suspense: false, compare: dequal, isPaused: function () { return false; } }, webPreset);
export { cache };
export default defaultConfig;
