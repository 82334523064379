// Generated by ReScript, PLEASE EDIT WITH CARE


function toString(breakpoint) {
  switch (breakpoint) {
    case /* Xs */0 :
        return "xs";
    case /* Sm */1 :
        return "sm";
    case /* Md */2 :
        return "md";
    case /* Lg */3 :
        return "lg";
    case /* Xl */4 :
        return "xl";
    
  }
}

function fromString(breakpoint) {
  switch (breakpoint) {
    case "lg" :
        return /* Lg */3;
    case "md" :
        return /* Md */2;
    case "sm" :
        return /* Sm */1;
    case "xl" :
        return /* Xl */4;
    case "xs" :
        return /* Xs */0;
    default:
      return ;
  }
}

export {
  toString ,
  fromString ,
}
/* No side effect */
