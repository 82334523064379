// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Js_dict from "../../../../../rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../../../rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../../rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../rescript/lib/es6/belt_Option.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";

function nullable(value, encoder) {
  return Belt_Option.mapWithDefault(value, Json__Encode.$$null, encoder);
}

function simpleVariant(values, value) {
  return Belt_Array.reduce(values, Json__Encode.string("THIS SHOULD NEVER HAPPEN!"), (function (acc, param) {
                if (Caml_obj.equal(value, param[1])) {
                  return Json__Encode.array([param[0]], Json__Encode.string);
                } else {
                  return acc;
                }
              }));
}

function deccoVariantWithPayload(value, name, payloadEncoder) {
  return Json__Encode.array([
              Json__Encode.string(name),
              Curry._1(payloadEncoder, value)
            ], (function (x) {
                return x;
              }));
}

function dictWithEncoder(d, encoder) {
  return Json__Encode.dict(Js_dict.map(Curry.__1(encoder), d));
}

function addIf(notEqualTo, previous, encode, input) {
  var value = input[1];
  if (Caml_obj.notequal(value, notEqualTo)) {
    return Belt_Array.concat(previous, [[
                  input[0],
                  Curry._1(encode, value)
                ]]);
  } else {
    return previous;
  }
}

var Encode = {
  encode: Json__Encode.encode,
  string: Json__Encode.string,
  $$int: Json__Encode.$$int,
  $$float: Json__Encode.$$float,
  bool: Json__Encode.bool,
  $$null: Json__Encode.$$null,
  array: Json__Encode.array,
  list: Json__Encode.list,
  object: Json__Encode.object,
  dict: Json__Encode.dict,
  nullable: nullable,
  simpleVariant: simpleVariant,
  deccoVariantWithPayload: deccoVariantWithPayload,
  dictWithEncoder: dictWithEncoder,
  addIf: addIf
};

function lazy_(thunk) {
  return Json__Decode.andThen(Json__Decode.succeed(undefined), thunk);
}

function $$default(encoder, $$default$1) {
  return Json__Decode.oneOf(encoder, [Json__Decode.succeed($$default$1)]);
}

function fieldWithDefault(name, decoder, defaultTo) {
  return Json__Decode.map(Json__Decode.option(Json__Decode.field(name, decoder)), (function (v) {
                return Belt_Option.getWithDefault(v, defaultTo);
              }));
}

function exactString(exactValue) {
  return Json__Decode.andThen(Json__Decode.string, (function (str) {
                if (str === exactValue) {
                  return Json__Decode.succeed(Json__Decode.string);
                } else {
                  return Json__Decode.fail("String is not the expected value of: " + exactValue + "");
                }
              }));
}

function exactStringVariant(exactString, variant) {
  return Json__Decode.andThen(Json__Decode.index(0, Json__Decode.string), (function (str) {
                if (str === exactString) {
                  return Json__Decode.succeed(variant);
                } else {
                  return Json__Decode.fail("Not " + exactString + "");
                }
              }));
}

function exactVariantChoice(exactValue) {
  return Json__Decode.andThen(Json__Decode.index(0, Json__Decode.string), (function (str) {
                if (str === exactValue) {
                  return Json__Decode.succeed(exactValue);
                } else {
                  return Json__Decode.fail("Trying to decode " + str + " but it doesn't match value " + exactValue + "");
                }
              }));
}

function simpleVariant$1(values) {
  var acceptedValues = values.map(function (param) {
          return "\"" + param[0] + "\"";
        }).join(" or ");
  return Json__Decode.andThen(Json__Decode.index(0, Json__Decode.string), (function (str) {
                var match = values.find(function (param) {
                      return param[0] === str;
                    });
                if (match !== undefined) {
                  return Json__Decode.succeed(match[1]);
                } else {
                  return Json__Decode.fail("Trying to decode but the string " + str + " is not supported! Accepted values: " + acceptedValues + "");
                }
              }));
}

function deccoVariantWithPayload$1(name, decoder, f) {
  return Json__Decode.map2(Json__Decode.index(0, exactString(name)), Json__Decode.index(1, decoder), (function (param, value) {
                return Curry._1(f, value);
              }));
}

function stringifiedPayloadVariant(name, decoder, then) {
  return Json__Decode.map(Json__Decode.andThen(Json__Decode.string, (function (str) {
                    if (!str.includes(name)) {
                      return Json__Decode.fail("Not " + name + "");
                    }
                    var split = str.split(name);
                    var secondPart = Belt_Array.get(split, 1);
                    if (secondPart === undefined) {
                      return Json__Decode.fail("Not " + name + "");
                    }
                    var e = Json__Decode.decodeString(secondPart, decoder);
                    if (e.TAG === /* Ok */0) {
                      return Json__Decode.succeed(e._0);
                    } else {
                      return Json__Decode.fail("Could not parse payload in " + name + "");
                    }
                  })), Curry.__1(then));
}

var Decode = {
  string: Json__Decode.string,
  bool: Json__Decode.bool,
  $$int: Json__Decode.$$int,
  $$float: Json__Decode.$$float,
  nullable: Json__Decode.nullable,
  array: Json__Decode.array,
  list: Json__Decode.list,
  dict: Json__Decode.dict,
  keyValuePairs: Json__Decode.keyValuePairs,
  tuple2: Json__Decode.tuple2,
  tuple3: Json__Decode.tuple3,
  tuple4: Json__Decode.tuple4,
  tuple5: Json__Decode.tuple5,
  tuple6: Json__Decode.tuple6,
  tuple7: Json__Decode.tuple7,
  tuple8: Json__Decode.tuple8,
  field: Json__Decode.field,
  at: Json__Decode.at,
  index: Json__Decode.index,
  option: Json__Decode.option,
  oneOf: Json__Decode.oneOf,
  decodeString: Json__Decode.decodeString,
  decodeValue: Json__Decode.decodeValue,
  errorToString: Json__Decode.errorToString,
  map: Json__Decode.map,
  map2: Json__Decode.map2,
  map3: Json__Decode.map3,
  map4: Json__Decode.map4,
  map5: Json__Decode.map5,
  map6: Json__Decode.map6,
  map7: Json__Decode.map7,
  map8: Json__Decode.map8,
  value: Json__Decode.value,
  $$null: Json__Decode.$$null,
  succeed: Json__Decode.succeed,
  fail: Json__Decode.fail,
  andMap: Json__Decode.andMap,
  andThen: Json__Decode.andThen,
  lazy_: lazy_,
  $$default: $$default,
  fieldWithDefault: fieldWithDefault,
  exactString: exactString,
  exactStringVariant: exactStringVariant,
  exactVariantChoice: exactVariantChoice,
  simpleVariant: simpleVariant$1,
  deccoVariantWithPayload: deccoVariantWithPayload$1,
  stringifiedPayloadVariant: stringifiedPayloadVariant
};

export {
  Encode ,
  Decode ,
}
/* Json__Decode Not a pure module */
