// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LegacyShowside_Breakpoint2$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/LegacyShowside/LegacyShowside_Breakpoint2.bs.js";
function calc(vw) {
  if (vw <= 360) {
    return /* Xs */0;
  } else if (vw <= 600) {
    return /* Sm */1;
  } else if (vw < 1024) {
    return /* Md */2;
  } else if (vw < 1366) {
    return /* Lg */3;
  } else {
    return /* Xl */4;
  }
}
function toString(breakpoint) {
  switch (breakpoint) {
    case /* Xs */0:
      return "xs";
    case /* Sm */1:
      return "sm";
    case /* Md */2:
      return "md";
    case /* Lg */3:
      return "lg";
    case /* Xl */4:
      return "xl";
  }
}
var fromString = LegacyShowside_Breakpoint2$ApiTypes.fromString;
export { fromString, calc, toString };
/* No side effect */