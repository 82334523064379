// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Swr from "swr";
import Swr$1 from "swr";
import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Fetch from "../../../../bs-fetch/lib/es6_global/src/Fetch.bs.js";
import * as React from "react";
import * as $$Promise from "../../../../@ryyppy/rescript-promise/lib/es6_global/src/Promise.bs.js";
import * as Belt_Option from "../../../../rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "../../../../rescript/lib/es6/caml_exceptions.js";
import * as Caml_js_exceptions from "../../../../rescript/lib/es6/caml_js_exceptions.js";

var NetworkError = /* @__PURE__ */Caml_exceptions.create("SWR.NetworkError");

var ServerError = /* @__PURE__ */Caml_exceptions.create("SWR.ServerError");

var DecodeError = /* @__PURE__ */Caml_exceptions.create("SWR.DecodeError");

var ParseError = /* @__PURE__ */Caml_exceptions.create("SWR.ParseError");

function useSWR(shouldRetryOnErrorOpt, refreshIntervalOpt, refreshWhenHiddenOpt, revalidateOnFocusOpt, key, fetcher) {
  var shouldRetryOnError = shouldRetryOnErrorOpt !== undefined ? shouldRetryOnErrorOpt : true;
  var refreshInterval = refreshIntervalOpt !== undefined ? refreshIntervalOpt : 0;
  var refreshWhenHidden = refreshWhenHiddenOpt !== undefined ? refreshWhenHiddenOpt : false;
  var revalidateOnFocus = revalidateOnFocusOpt !== undefined ? revalidateOnFocusOpt : true;
  return Swr$1(key, fetcher, {
              refreshInterval: refreshInterval,
              refreshWhenHidden: refreshWhenHidden,
              shouldRetryOnError: shouldRetryOnError,
              revalidateOnFocus: revalidateOnFocus
            });
}

function useSWRWithInitialData(shouldRetryOnErrorOpt, refreshIntervalOpt, refreshWhenHiddenOpt, initialData, revalidateOnMount, revalidateOnFocusOpt, revalidateFirstPageOpt, key, fetcher) {
  var shouldRetryOnError = shouldRetryOnErrorOpt !== undefined ? shouldRetryOnErrorOpt : true;
  var refreshInterval = refreshIntervalOpt !== undefined ? refreshIntervalOpt : 0;
  var refreshWhenHidden = refreshWhenHiddenOpt !== undefined ? refreshWhenHiddenOpt : false;
  var revalidateOnFocus = revalidateOnFocusOpt !== undefined ? revalidateOnFocusOpt : true;
  var revalidateFirstPage = revalidateFirstPageOpt !== undefined ? revalidateFirstPageOpt : true;
  return Swr$1(key, fetcher, {
              refreshInterval: refreshInterval,
              refreshWhenHidden: refreshWhenHidden,
              shouldRetryOnError: shouldRetryOnError,
              initialData: initialData,
              revalidateOnMount: revalidateOnMount,
              revalidateOnFocus: revalidateOnFocus,
              revalidateFirstPage: revalidateFirstPage
            });
}

function useSWRInfinite(refreshInterval, refreshWhenHidden, initialSize, persistSize, revalidateAll, revalidateOnFocus, revalidateFirstPage, getKey, fetcher) {
  return Swr.useSWRInfinite(getKey, fetcher, {
              refreshInterval: refreshInterval,
              refreshWhenHidden: refreshWhenHidden,
              initialSize: initialSize,
              persistSize: persistSize,
              revalidateAll: revalidateAll,
              revalidateOnFocus: revalidateOnFocus,
              revalidateFirstPage: revalidateFirstPage
            });
}

function mutate(key, fn, revalidate) {
  return Swr.mutate(key, fn, revalidate);
}

function revalidate(key) {
  return Swr.mutate(key);
}

var SWRConfig = {};

function SWR$Config(Props) {
  var refreshWhenHiddenOpt = Props.refreshWhenHidden;
  var refreshIntervalOpt = Props.refreshInterval;
  var shouldRetryOnErrorOpt = Props.shouldRetryOnError;
  var revalidateOnFocusOpt = Props.revalidateOnFocus;
  var children = Props.children;
  var refreshWhenHidden = refreshWhenHiddenOpt !== undefined ? refreshWhenHiddenOpt : false;
  var refreshInterval = refreshIntervalOpt !== undefined ? refreshIntervalOpt : 0;
  var shouldRetryOnError = shouldRetryOnErrorOpt !== undefined ? shouldRetryOnErrorOpt : true;
  var revalidateOnFocus = revalidateOnFocusOpt !== undefined ? revalidateOnFocusOpt : true;
  var value = {
    refreshInterval: refreshInterval,
    refreshWhenHidden: refreshWhenHidden,
    shouldRetryOnError: shouldRetryOnError,
    revalidateOnFocus: revalidateOnFocus
  };
  return React.createElement(Swr.SWRConfig, {
              value: value,
              children: children
            });
}

var Config = {
  make: SWR$Config
};

function fetcher(logErrorOpt, headers, asyncDecoder, decoder, url) {
  var logError = logErrorOpt !== undefined ? logErrorOpt : true;
  var headers$1 = Belt_Option.getWithDefault(headers, {});
  return $$Promise.$$catch(fetch(url, Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers$1), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)), (function (err) {
                        return Promise.reject({
                                    RE_EXN_ID: NetworkError,
                                    _1: Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.as_js_exn(err), (function (prim) {
                                                return prim.message;
                                              })), "Network error")
                                  });
                      })).then(function (res) {
                    var status = res.status;
                    if (status !== 200) {
                      return $$Promise.$$catch(Fetch.$$Response.text(res), (function (param) {
                                      return Promise.resolve("");
                                    })).then(function (text) {
                                  return Promise.reject({
                                              RE_EXN_ID: ServerError,
                                              _1: "Error fetching, non-200 response. " + String(status) + " " + text + ""
                                            });
                                });
                    } else {
                      return Promise.resolve(res);
                    }
                  }).then(function (res) {
                  return $$Promise.$$catch(Fetch.$$Response.json(res), (function (param) {
                                return Promise.reject({
                                            RE_EXN_ID: ParseError,
                                            _1: "Could not parse as JSON"
                                          });
                              }));
                }).then(function (json) {
                if (asyncDecoder !== undefined) {
                  return Curry._1(asyncDecoder, json);
                } else {
                  return Promise.resolve(Curry._1(decoder, json));
                }
              }).then(function (decoded) {
              if (decoded.TAG === /* Ok */0) {
                return Promise.resolve(decoded._0);
              }
              var err = decoded._0;
              if (logError) {
                console.error(err);
              }
              return Promise.reject({
                          RE_EXN_ID: DecodeError,
                          _1: "Could not decode JSON " + err + ""
                        });
            });
}

var cache = Swr.cache.cache;

var Cache = {
  cache: cache
};

export {
  NetworkError ,
  ServerError ,
  DecodeError ,
  ParseError ,
  useSWR ,
  useSWRWithInitialData ,
  useSWRInfinite ,
  mutate ,
  revalidate ,
  SWRConfig ,
  Config ,
  fetcher ,
  Cache ,
}
/* cache Not a pure module */
