// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Js_dict from "../../../../rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../rescript/lib/es6/js_json.js";
import * as Belt_List from "../../../../rescript/lib/es6/belt_List.js";
import * as Belt_Array from "../../../../rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../rescript/lib/es6/caml_option.js";
import * as Json__Encode from "./Json__Encode.bs.js";

function optToRes(option, err) {
  if (option !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Caml_option.valFromOption(option)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: err
          };
  }
}

function resMapError(res, f) {
  if (res.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: res._0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Curry._1(f, res._0)
          };
  }
}

function resAndMap(resF, resV) {
  return Belt_Result.flatMap(resF, (function (f) {
                return Belt_Result.map(resV, Curry.__1(f));
              }));
}

function string(j) {
  return optToRes(Js_json.decodeString(j), {
              TAG: /* Failure */0,
              _0: "Expecting a STRING",
              _1: j
            });
}

function bool(j) {
  return optToRes(Js_json.decodeBoolean(j), {
              TAG: /* Failure */0,
              _0: "Expecting a BOOL",
              _1: j
            });
}

function $$int(j) {
  return optToRes(Belt_Option.map(Js_json.decodeNumber(j), (function (prim) {
                    return prim | 0;
                  })), {
              TAG: /* Failure */0,
              _0: "Expecting an INT",
              _1: j
            });
}

function $$float(j) {
  return optToRes(Js_json.decodeNumber(j), {
              TAG: /* Failure */0,
              _0: "Expecting a FLOAT",
              _1: j
            });
}

function array(aDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an ARRAY",
                    _1: j
                  }), (function (arr) {
                  return arr.reduce((function (acc, cur, index) {
                                if (acc.TAG !== /* Ok */0) {
                                  return acc;
                                }
                                var error = Curry._1(aDecoder, cur);
                                if (error.TAG === /* Ok */0) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: acc._0.concat([error._0])
                                        };
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            TAG: /* Index */1,
                                            _0: index,
                                            _1: error._0
                                          }
                                        };
                                }
                              }), {
                              TAG: /* Ok */0,
                              _0: []
                            });
                }));
  };
}

function list(aDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an ARRAY",
                    _1: j
                  }), (function (arr) {
                  return Belt_Array.reverse(arr).reduce((function (acc, cur, index) {
                                if (acc.TAG !== /* Ok */0) {
                                  return acc;
                                }
                                var error = Curry._1(aDecoder, cur);
                                if (error.TAG === /* Ok */0) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: {
                                            hd: error._0,
                                            tl: acc._0
                                          }
                                        };
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            TAG: /* Index */1,
                                            _0: index,
                                            _1: error._0
                                          }
                                        };
                                }
                              }), {
                              TAG: /* Ok */0,
                              _0: /* [] */0
                            });
                }));
  };
}

function dict(aDecoder) {
  return function (j) {
    return Belt_Result.map(Belt_Result.flatMap(optToRes(Js_json.decodeObject(j), {
                        TAG: /* Failure */0,
                        _0: "Expecting an OBJECT",
                        _1: j
                      }), (function (obj) {
                      return Js_dict.entries(obj).reduce((function (acc, param) {
                                    var key = param[0];
                                    if (acc.TAG !== /* Ok */0) {
                                      return acc;
                                    }
                                    var error = Curry._1(aDecoder, param[1]);
                                    if (error.TAG === /* Ok */0) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: acc._0.concat([[
                                                      key,
                                                      error._0
                                                    ]])
                                            };
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                TAG: /* Field */2,
                                                _0: key,
                                                _1: error._0
                                              }
                                            };
                                    }
                                  }), {
                                  TAG: /* Ok */0,
                                  _0: []
                                });
                    })), Js_dict.fromArray);
  };
}

function keyValuePairs(aDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeObject(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an OBJECT",
                    _1: j
                  }), (function (obj) {
                  return Js_dict.entries(obj).reduce((function (acc, param) {
                                var key = param[0];
                                if (acc.TAG !== /* Ok */0) {
                                  return acc;
                                }
                                var error = Curry._1(aDecoder, param[1]);
                                if (error.TAG === /* Ok */0) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: acc._0.concat([[
                                                  key,
                                                  error._0
                                                ]])
                                        };
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            TAG: /* Field */2,
                                            _0: key,
                                            _1: error._0
                                          }
                                        };
                                }
                              }), {
                              TAG: /* Ok */0,
                              _0: []
                            });
                }));
  };
}

function tuple2(aDecoder, bDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an ARRAY",
                    _1: j
                  }), (function (arr) {
                  if (arr.length !== 2) {
                    return {
                            TAG: /* Error */1,
                            _0: {
                              TAG: /* Failure */0,
                              _0: "Expecting an ARRAY with 2 elements",
                              _1: j
                            }
                          };
                  }
                  var a = arr[0];
                  var b = arr[1];
                  return resAndMap(resAndMap({
                                  TAG: /* Ok */0,
                                  _0: (function (a, b) {
                                      return [
                                              a,
                                              b
                                            ];
                                    })
                                }, Curry._1(aDecoder, a)), Curry._1(bDecoder, b));
                }));
  };
}

function tuple3(aDecoder, bDecoder, cDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an ARRAY",
                    _1: j
                  }), (function (arr) {
                  if (arr.length !== 3) {
                    return {
                            TAG: /* Error */1,
                            _0: {
                              TAG: /* Failure */0,
                              _0: "Expecting an ARRAY with 3 elements",
                              _1: j
                            }
                          };
                  }
                  var a = arr[0];
                  var b = arr[1];
                  var c = arr[2];
                  return resAndMap(resAndMap(resAndMap({
                                      TAG: /* Ok */0,
                                      _0: (function (a, b, c) {
                                          return [
                                                  a,
                                                  b,
                                                  c
                                                ];
                                        })
                                    }, Curry._1(aDecoder, a)), Curry._1(bDecoder, b)), Curry._1(cDecoder, c));
                }));
  };
}

function tuple4(aDecoder, bDecoder, cDecoder, dDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an ARRAY",
                    _1: j
                  }), (function (arr) {
                  if (arr.length !== 4) {
                    return {
                            TAG: /* Error */1,
                            _0: {
                              TAG: /* Failure */0,
                              _0: "Expecting an ARRAY with 4 elements",
                              _1: j
                            }
                          };
                  }
                  var a = arr[0];
                  var b = arr[1];
                  var c = arr[2];
                  var d = arr[3];
                  return resAndMap(resAndMap(resAndMap(resAndMap({
                                          TAG: /* Ok */0,
                                          _0: (function (a, b, c, d) {
                                              return [
                                                      a,
                                                      b,
                                                      c,
                                                      d
                                                    ];
                                            })
                                        }, Curry._1(aDecoder, a)), Curry._1(bDecoder, b)), Curry._1(cDecoder, c)), Curry._1(dDecoder, d));
                }));
  };
}

function tuple5(aDecoder, bDecoder, cDecoder, dDecoder, eDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an ARRAY",
                    _1: j
                  }), (function (arr) {
                  if (arr.length !== 5) {
                    return {
                            TAG: /* Error */1,
                            _0: {
                              TAG: /* Failure */0,
                              _0: "Expecting an ARRAY with 5 elements",
                              _1: j
                            }
                          };
                  }
                  var a = arr[0];
                  var b = arr[1];
                  var c = arr[2];
                  var d = arr[3];
                  var e = arr[4];
                  return resAndMap(resAndMap(resAndMap(resAndMap(resAndMap({
                                              TAG: /* Ok */0,
                                              _0: (function (a, b, c, d, e) {
                                                  return [
                                                          a,
                                                          b,
                                                          c,
                                                          d,
                                                          e
                                                        ];
                                                })
                                            }, Curry._1(aDecoder, a)), Curry._1(bDecoder, b)), Curry._1(cDecoder, c)), Curry._1(dDecoder, d)), Curry._1(eDecoder, e));
                }));
  };
}

function tuple6(aDecoder, bDecoder, cDecoder, dDecoder, eDecoder, fDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an ARRAY",
                    _1: j
                  }), (function (arr) {
                  if (arr.length !== 6) {
                    return {
                            TAG: /* Error */1,
                            _0: {
                              TAG: /* Failure */0,
                              _0: "Expecting an ARRAY with 6 elements",
                              _1: j
                            }
                          };
                  }
                  var a = arr[0];
                  var b = arr[1];
                  var c = arr[2];
                  var d = arr[3];
                  var e = arr[4];
                  var f = arr[5];
                  return resAndMap(resAndMap(resAndMap(resAndMap(resAndMap(resAndMap({
                                                  TAG: /* Ok */0,
                                                  _0: (function (a, b, c, d, e, f) {
                                                      return [
                                                              a,
                                                              b,
                                                              c,
                                                              d,
                                                              e,
                                                              f
                                                            ];
                                                    })
                                                }, Curry._1(aDecoder, a)), Curry._1(bDecoder, b)), Curry._1(cDecoder, c)), Curry._1(dDecoder, d)), Curry._1(eDecoder, e)), Curry._1(fDecoder, f));
                }));
  };
}

function tuple7(aDecoder, bDecoder, cDecoder, dDecoder, eDecoder, fDecoder, gDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an ARRAY",
                    _1: j
                  }), (function (arr) {
                  if (arr.length !== 7) {
                    return {
                            TAG: /* Error */1,
                            _0: {
                              TAG: /* Failure */0,
                              _0: "Expecting an ARRAY with 7 elements",
                              _1: j
                            }
                          };
                  }
                  var a = arr[0];
                  var b = arr[1];
                  var c = arr[2];
                  var d = arr[3];
                  var e = arr[4];
                  var f = arr[5];
                  var g = arr[6];
                  return resAndMap(resAndMap(resAndMap(resAndMap(resAndMap(resAndMap(resAndMap({
                                                      TAG: /* Ok */0,
                                                      _0: (function (a, b, c, d, e, f, g) {
                                                          return [
                                                                  a,
                                                                  b,
                                                                  c,
                                                                  d,
                                                                  e,
                                                                  f,
                                                                  g
                                                                ];
                                                        })
                                                    }, Curry._1(aDecoder, a)), Curry._1(bDecoder, b)), Curry._1(cDecoder, c)), Curry._1(dDecoder, d)), Curry._1(eDecoder, e)), Curry._1(fDecoder, f)), Curry._1(gDecoder, g));
                }));
  };
}

function tuple8(aDecoder, bDecoder, cDecoder, dDecoder, eDecoder, fDecoder, gDecoder, hDecoder) {
  return function (j) {
    return Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                    TAG: /* Failure */0,
                    _0: "Expecting an ARRAY",
                    _1: j
                  }), (function (arr) {
                  if (arr.length !== 8) {
                    return {
                            TAG: /* Error */1,
                            _0: {
                              TAG: /* Failure */0,
                              _0: "Expecting an ARRAY with 8 elements",
                              _1: j
                            }
                          };
                  }
                  var a = arr[0];
                  var b = arr[1];
                  var c = arr[2];
                  var d = arr[3];
                  var e = arr[4];
                  var f = arr[5];
                  var g = arr[6];
                  var h = arr[7];
                  return resAndMap(resAndMap(resAndMap(resAndMap(resAndMap(resAndMap(resAndMap(resAndMap({
                                                          TAG: /* Ok */0,
                                                          _0: (function (a, b, c, d, e, f, g, h) {
                                                              return [
                                                                      a,
                                                                      b,
                                                                      c,
                                                                      d,
                                                                      e,
                                                                      f,
                                                                      g,
                                                                      h
                                                                    ];
                                                            })
                                                        }, Curry._1(aDecoder, a)), Curry._1(bDecoder, b)), Curry._1(cDecoder, c)), Curry._1(dDecoder, d)), Curry._1(eDecoder, e)), Curry._1(fDecoder, f)), Curry._1(gDecoder, g)), Curry._1(hDecoder, h));
                }));
  };
}

function fieldHelp(j, key) {
  return Belt_Result.flatMap(optToRes(Js_json.decodeObject(j), {
                  TAG: /* Failure */0,
                  _0: "Expecting an OBJECT with a field named '" + key + "'",
                  _1: j
                }), (function (sObj) {
                return optToRes(Js_dict.get(sObj, key), {
                            TAG: /* Failure */0,
                            _0: "Expecting an OBJECT with a field named '" + key + "'",
                            _1: j
                          });
              }));
}

function field(key, aDecoder) {
  return function (j) {
    return Belt_Result.flatMap(fieldHelp(j, key), (function (jv) {
                  return resMapError(Curry._1(aDecoder, jv), (function (e) {
                                return {
                                        TAG: /* Field */2,
                                        _0: key,
                                        _1: e
                                      };
                              }));
                }));
  };
}

function atHelp(firstKey, keys, decoder) {
  return field(firstKey, keys ? atHelp(keys.hd, keys.tl, decoder) : decoder);
}

function at(firstKey, keys, decoder) {
  return atHelp(firstKey, Belt_List.fromArray(keys), decoder);
}

function index(index$1, aDecoder) {
  return function (j) {
    return Belt_Result.flatMap(Belt_Result.flatMap(optToRes(Js_json.decodeArray(j), {
                        TAG: /* Failure */0,
                        _0: "Expecting an ARRAY",
                        _1: j
                      }), (function (arr) {
                      if (arr.length === 0) {
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  TAG: /* Failure */0,
                                  _0: "Expecting a NON-EMPTY array. Need index " + String(index$1) + " but only saw an empty array",
                                  _1: j
                                }
                              };
                      } else {
                        return optToRes(Belt_Array.get(arr, index$1), index$1 >= arr.length ? ({
                                        TAG: /* Failure */0,
                                        _0: "Expecting a LONGER array. Need index " + String(index$1) + " but only saw " + String(arr.length) + " entries",
                                        _1: j
                                      }) : ({
                                        TAG: /* Failure */0,
                                        _0: "Expecting a POSITIVE index but saw " + String(index$1) + "",
                                        _1: j
                                      }));
                      }
                    })), (function (jv) {
                  return resMapError(Curry._1(aDecoder, jv), (function (e) {
                                return {
                                        TAG: /* Index */1,
                                        _0: index$1,
                                        _1: e
                                      };
                              }));
                }));
  };
}

function option(aDecoder) {
  return function (j) {
    return {
            TAG: /* Ok */0,
            _0: Belt_Result.getWithDefault(Belt_Result.map(Curry._1(aDecoder, j), (function (a) {
                        return Caml_option.some(a);
                      })), undefined)
          };
  };
}

function oneOf(firstDecoder, decoders) {
  return function (j) {
    var a = Curry._1(firstDecoder, j);
    if (a.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: a._0
            };
    } else {
      return resMapError(decoders.reduce((function (acc, cur) {
                        if (acc.TAG === /* Ok */0) {
                          return acc;
                        }
                        var match = acc._0;
                        var ne = Curry._1(cur, j);
                        if (ne.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: ne._0
                                };
                        } else {
                          return {
                                  TAG: /* Error */1,
                                  _0: [
                                    match[0],
                                    {
                                      hd: ne._0,
                                      tl: match[1]
                                    }
                                  ]
                                };
                        }
                      }), {
                      TAG: /* Error */1,
                      _0: [
                        a._0,
                        /* [] */0
                      ]
                    }), (function (param) {
                    return {
                            TAG: /* OneOf */3,
                            _0: param[0],
                            _1: Belt_List.toArray(param[1])
                          };
                  }));
    }
  };
}

function fromString(s) {
  try {
    return Caml_option.some(JSON.parse(s));
  }
  catch (exn){
    return ;
  }
}

function decodeString(s, decoder) {
  return Belt_Result.flatMap(optToRes(fromString(s), {
                  TAG: /* Failure */0,
                  _0: "Invalid JSON",
                  _1: s
                }), decoder);
}

function decodeValue(j, decoder) {
  return Curry._1(decoder, j);
}

function joinList(l, s) {
  if (l) {
    return Belt_List.reduce(l.tl, l.hd, (function (acc, cur) {
                  return acc + s + cur;
                }));
  } else {
    return "";
  }
}

function indent(str) {
  return joinList(Belt_List.fromArray(str.split("\n")), "\n    ");
}

function errorToStringHelp(_error, _context) {
  while(true) {
    var context = _context;
    var error = _error;
    switch (error.TAG | 0) {
      case /* Failure */0 :
          var reveresed = Belt_List.reverse(context);
          var intro = reveresed ? "Problem with the value at json" + joinList(reveresed, "") + ":\n\n    " : "Problem with the given value:\n\n";
          return "" + intro + "" + indent(Json__Encode.encode(error._1, 4)) + "\n\n" + error._0 + "";
      case /* Index */1 :
          var fieldName = "[" + String(error._0) + "]";
          _context = {
            hd: fieldName,
            tl: context
          };
          _error = error._1;
          continue ;
      case /* Field */2 :
          var fieldName$1 = "[\"" + error._0 + "\"]";
          _context = {
            hd: fieldName$1,
            tl: context
          };
          _error = error._1;
          continue ;
      case /* OneOf */3 :
          var errs = error._1;
          var firstErr = error._0;
          if (errs.length !== 0) {
            var reveresed$1 = Belt_List.reverse(context);
            var starter = reveresed$1 ? "oneOf at json" + joinList(reveresed$1, "") + "" : "oneOf";
            var intro$1 = "" + starter + " failed in the following " + String(Belt_List.length(context)) + " ways:";
            return joinList({
                        hd: intro$1,
                        tl: Belt_List.mapWithIndex(Belt_List.fromArray(Belt_Array.concat([firstErr], errs)), (function (i, err) {
                                return "\n\n(" + String(i + 1 | 0) + ") " + indent(errorToStringHelp(err, /* [] */0));
                              }))
                      }, "\n\n");
          }
          _error = firstErr;
          continue ;
      
    }
  };
}

function errorToString(e) {
  return errorToStringHelp(e, /* [] */0);
}

function map(decoder, f) {
  return function (j) {
    return Belt_Result.map(Curry._1(decoder, j), f);
  };
}

function map2(decoderA, decoderB, f) {
  return function (j) {
    return Belt_Result.flatMap(Curry._1(decoderA, j), (function (a) {
                  return Belt_Result.map(Curry._1(decoderB, j), (function (b) {
                                return Curry._2(f, a, b);
                              }));
                }));
  };
}

function andMap(decoderF, decoderA) {
  return map2(decoderF, decoderA, (function (f, a) {
                return Curry._1(f, a);
              }));
}

function $$null(v) {
  return function (j) {
    return Belt_Result.map(optToRes(Js_json.decodeNull(j), {
                    TAG: /* Failure */0,
                    _0: "invalid null",
                    _1: j
                  }), (function (param) {
                  return v;
                }));
  };
}

function nullable(decoder) {
  var f = function (a) {
    return Caml_option.some(a);
  };
  return oneOf((function (j) {
                return Belt_Result.map(Curry._1(decoder, j), f);
              }), [$$null(undefined)]);
}

function value(j) {
  return {
          TAG: /* Ok */0,
          _0: j
        };
}

function andThen(decoder, f) {
  return function (j) {
    return Belt_Result.flatMap(Curry._1(decoder, j), (function (a) {
                  var b = Curry._1(f, a);
                  return Curry._1(b, j);
                }));
  };
}

function succeed(a) {
  return function (param) {
    return {
            TAG: /* Ok */0,
            _0: a
          };
  };
}

function fail(err) {
  return function (j) {
    return {
            TAG: /* Error */1,
            _0: {
              TAG: /* Failure */0,
              _0: err,
              _1: j
            }
          };
  };
}

function map3(decoderA, decoderB, decoderC, f) {
  return andMap(andMap(andMap((function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: f
                              };
                      }), decoderA), decoderB), decoderC);
}

function map4(decoderA, decoderB, decoderC, decoderD, f) {
  return andMap(andMap(andMap(andMap((function (param) {
                            return {
                                    TAG: /* Ok */0,
                                    _0: f
                                  };
                          }), decoderA), decoderB), decoderC), decoderD);
}

function map5(decoderA, decoderB, decoderC, decoderD, decoderE, f) {
  return andMap(andMap(andMap(andMap(andMap((function (param) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: f
                                      };
                              }), decoderA), decoderB), decoderC), decoderD), decoderE);
}

function map6(decoderA, decoderB, decoderC, decoderD, decoderE, decoderF, f) {
  return andMap(andMap(andMap(andMap(andMap(andMap((function (param) {
                                    return {
                                            TAG: /* Ok */0,
                                            _0: f
                                          };
                                  }), decoderA), decoderB), decoderC), decoderD), decoderE), decoderF);
}

function map7(decoderA, decoderB, decoderC, decoderD, decoderE, decoderF, decoderG, f) {
  return andMap(andMap(andMap(andMap(andMap(andMap(andMap((function (param) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: f
                                              };
                                      }), decoderA), decoderB), decoderC), decoderD), decoderE), decoderF), decoderG);
}

function map8(decoderA, decoderB, decoderC, decoderD, decoderE, decoderF, decoderG, decoderH, f) {
  return andMap(andMap(andMap(andMap(andMap(andMap(andMap(andMap((function (param) {
                                            return {
                                                    TAG: /* Ok */0,
                                                    _0: f
                                                  };
                                          }), decoderA), decoderB), decoderC), decoderD), decoderE), decoderF), decoderG), decoderH);
}

export {
  string ,
  bool ,
  $$int ,
  $$float ,
  nullable ,
  array ,
  list ,
  dict ,
  keyValuePairs ,
  tuple2 ,
  tuple3 ,
  tuple4 ,
  tuple5 ,
  tuple6 ,
  tuple7 ,
  tuple8 ,
  field ,
  at ,
  index ,
  option ,
  oneOf ,
  decodeString ,
  decodeValue ,
  errorToString ,
  map ,
  map2 ,
  map3 ,
  map4 ,
  map5 ,
  map6 ,
  map7 ,
  map8 ,
  value ,
  $$null ,
  succeed ,
  fail ,
  andMap ,
  andThen ,
}
/* Json__Encode Not a pure module */
