// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

var partial_arg = [
  [
    "Ua",
    /* Ua */0
  ],
  [
    "Gtag",
    /* Gtag */1
  ]
];

function toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg, param);
}

var fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Ua",
        /* Ua */0
      ],
      [
        "Gtag",
        /* Gtag */1
      ]
    ]);

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var GaVersion_codec = include.codec;

var GaVersion = {
  toJson: toJson,
  fromJson: fromJson,
  t_encode: t_encode,
  t_decode: t_decode,
  codec: GaVersion_codec
};

var gaVersion_encode = t_encode;

var gaVersion_decode = t_decode;

export {
  GaVersion ,
  gaVersion_encode ,
  gaVersion_decode ,
}
/* fromJson Not a pure module */
