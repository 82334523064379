// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "../../../../node_modules/rescript/lib/es6/js_string.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
function getEstimatedVideoMimeFromUrl(src) {
  var parts = Belt_Array.get(Belt_Array.keepMap(Belt_Option.getWithDefault(Caml_option.null_to_opt(Belt_Option.getWithDefault(Belt_Array.get(src.split("?"), 0), "").toLowerCase().match(/(.mp4|.m3u8|.mov)$/)), []), function (a) {
    return a;
  }), 0);
  if (parts === undefined) {
    return;
  }
  switch (parts) {
    case ".m3u8":
      return "application/x-mpegURL";
    case ".mov":
      return "video/quicktime";
    case ".mp4":
      return "video/mp4";
    default:
      return;
  }
}
function isVideoURL(src) {
  return Belt_Option.isSome(getEstimatedVideoMimeFromUrl(src));
}
function addProtocolIfMissing(str) {
  var isMissingProtocol = !/^([^\/])+:\/\//.test(str) && !/^\/([^\/]+)/.test(str) && str.trim() !== "";
  if (isMissingProtocol) {
    return str.replace(/^(?:(.*:)?\/\/)?(.*)/i, "https://$2");
  } else {
    return str;
  }
}
function resolveRelativePath(relativePath) {
  var isRelativePath = relativePath.startsWith("/");
  if (isRelativePath) {
    return window.location.origin + relativePath;
  } else {
    return relativePath;
  }
}
function maybeMakeUrl(url) {
  try {
    return Caml_option.some(new URL(url));
  } catch (e) {
    return;
  }
}
function getCanonical(param) {
  var link = Belt_Option.flatMap(Belt_Option.map(Caml_option.nullable_to_opt(document.querySelector("link[rel='canonical']")), function (el) {
    return Caml_option.nullable_to_opt(el.getAttribute("href"));
  }), function (href) {
    if (href === "") {
      return;
    } else {
      return href;
    }
  });
  var link$1;
  if (link !== undefined) {
    link$1 = link;
  } else {
    var $$location = window.location;
    link$1 = $$location.origin + $$location.pathname;
  }
  var link$2 = Belt_Option.isSome(Caml_option.null_to_opt(link$1.match(/^http/))) ? link$1 : "https://" + link$1;
  var url = maybeMakeUrl(link$2);
  if (url === undefined) {
    return "";
  }
  var url$1 = Caml_option.valFromOption(url);
  Belt_Array.forEach(Belt_Array.keep(Array.from(url$1.searchParams.keys()), function (param) {
    return Js_string.includes("QN", param);
  }), function (key) {
    url$1.searchParams.delete(key);
  });
  return url$1.toString();
}
function getCurrentHostname(param) {
  return window.location.hostname;
}
function getHostname(input) {
  var url = maybeMakeUrl(input);
  if (url !== undefined) {
    return Caml_option.valFromOption(url).hostname;
  }
}
function getPathname(input) {
  var url = maybeMakeUrl(input);
  if (url !== undefined) {
    return Caml_option.valFromOption(url).pathname;
  }
}
function getParam(input, key) {
  var url = maybeMakeUrl(input);
  if (url !== undefined) {
    return Caml_option.nullable_to_opt(Caml_option.valFromOption(url).searchParams.get(key));
  }
}
function changeParam(input, key, value) {
  var url = maybeMakeUrl(input);
  if (url === undefined) {
    return input;
  }
  var url$1 = Caml_option.valFromOption(url);
  if (value !== undefined) {
    url$1.searchParams.set(key, value);
  } else {
    url$1.searchParams.delete(key);
  }
  return url$1.toString();
}
function deleteParamWithRegex(input, keyRe) {
  var url = maybeMakeUrl(input);
  if (url === undefined) {
    return input;
  }
  var url$1 = Caml_option.valFromOption(url);
  Belt_Array.forEach(Belt_Array.keep(Array.from(url$1.searchParams.keys()), function (param) {
    return keyRe.test(param);
  }), function (key) {
    url$1.searchParams.delete(key);
  });
  return url$1.toString();
}
function addHostnameMaybe(url, hostname) {
  var match = getHostname(url);
  if (match !== undefined) {
    return url;
  } else {
    return hostname + url;
  }
}
export { getEstimatedVideoMimeFromUrl, isVideoURL, addProtocolIfMissing, resolveRelativePath, maybeMakeUrl, getCanonical, getCurrentHostname, getHostname, getPathname, getParam, changeParam, deleteParamWithRegex, addHostnameMaybe };
/* No side effect */