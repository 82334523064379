/**
 * Due to bug https://bugs.chromium.org/p/chromium/issues/detail?id=678075,
 * it's not reliable to detect if the browser is currently online or offline
 * based on `navigator.onLine`.
 * As a work around, we always assume it's online on first load, and change
 * the status upon `online` or `offline` events.
 */
var online = true;
var isOnline = function () { return online; };
var isDocumentVisible = function () {
    if (typeof document !== 'undefined' &&
        document.visibilityState !== undefined) {
        return document.visibilityState !== 'hidden';
    }
    // always assume it's visible
    return true;
};
var fetcher = function (url) { return fetch(url).then(function (res) { return res.json(); }); };
var registerOnFocus = function (cb) {
    if (typeof window !== 'undefined' &&
        window.addEventListener !== undefined &&
        typeof document !== 'undefined' &&
        document.addEventListener !== undefined) {
        // focus revalidate
        document.addEventListener('visibilitychange', function () { return cb(); }, false);
        window.addEventListener('focus', function () { return cb(); }, false);
    }
};
var registerOnReconnect = function (cb) {
    if (typeof window !== 'undefined' && window.addEventListener !== undefined) {
        // reconnect revalidate
        window.addEventListener('online', function () {
            online = true;
            cb();
        }, false);
        // nothing to revalidate, just update the status
        window.addEventListener('offline', function () { return (online = false); }, false);
    }
};
export default {
    isOnline: isOnline,
    isDocumentVisible: isDocumentVisible,
    fetcher: fetcher,
    registerOnFocus: registerOnFocus,
    registerOnReconnect: registerOnReconnect
};
