import { useEffect, useLayoutEffect } from 'react';
export var IS_SERVER = typeof window === 'undefined' ||
    // @ts-ignore
    !!(typeof Deno !== 'undefined' && Deno && Deno.version && Deno.version.deno);
// polyfill for requestAnimationFrame
export var rAF = IS_SERVER
    ? null
    : window['requestAnimationFrame']
        ? function (f) { return window['requestAnimationFrame'](f); }
        : function (f) { return setTimeout(f, 1); };
// React currently throws a warning when using useLayoutEffect on the server.
// To get around it, we can conditionally useEffect on the server (no-op) and
// useLayoutEffect in the browser.
export var useIsomorphicLayoutEffect = IS_SERVER ? useEffect : useLayoutEffect;
